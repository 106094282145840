import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostLink = ({ post }) => {

  let featuredImgFluid = post.frontmatter.featuredImage && post.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <article className="card ">
    <Link to={post.frontmatter.path}>
      {!!featuredImgFluid && (
        <Img fluid={featuredImgFluid} alt={post.frontmatter.title + "- Featured Shot"} />
      )}
    </Link>
    <header>
      <div className="post-meta">{post.frontmatter.date}</div>
      <h2 className="post-title">
        <Link to={post.frontmatter.path} className="post-link">
          {post.frontmatter.title}
        </Link>
      </h2>
    </header>
  </article>)
}
export default PostLink
